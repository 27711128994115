import React from 'react';
import fipeLogo from '../images/logo-fipe.png';
import OpcionaisDetalhes from './opcionaisDetalhes';
import {SITE_URL} from "../services/api";
import SwiperGallery from './SwiperGallery/SwiperGallery';
import Modal from "./Modal/Modal";
import BotaoTopo from "./BotaoTopo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faClock} from "@fortawesome/free-solid-svg-icons";
import {faArrowAltCircleRight} from "@fortawesome/free-regular-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import history from "../services/history";
import {Link} from "react-router-dom";
import slugify from "react-slugify";

class AdvertCardDetail extends React.Component {

    constructor(props) {
        super(props);
        this.Modal = React.createRef();
        this.SwiperGallery = React.createRef();
    }

    openModal = () => {
        this.Modal.current.openModal();
    };

    slideGalleryTo = index => { //futura implementação  (abre a foto clicada)
        this.SwiperGallery.current.setSelectedIndex(index);
    };

    render() {
        window.scroll(0, 0);

        let content = this.props.content;
        if(!content) {
            return <div> </div>;
        }

        let protocol = window.location.protocol;
        let slashes = protocol.concat("//");
        let host = slashes.concat(window.location.host);
        let link = slugify(`/detalhes/${content.id}/${content.brand.name}-${content.fipe.model}-${content.fipe.year}`);

        let percent = getPercent(content.fipe_percent_off);
        let created_at = new Date(content.created_at);
        let message =  `Olá, vi seu anúncio do *${content.fipe.model}* no site ${host}${link} . Tenho interesse, podemos conversar?`;
        let uriMessage = encodeURI(message);

        const fipePercent = this.props.displayFipePercent;
        const fipeValue = this.props.displayFipeValue;
        const carValue = this.props.displayCarValue;

        return (
            <React.Fragment>
                <div className="container" id="content">

                    <div className="row">
                        <div className="voltar col-md-12">
                            <h6>
                                <p onClick={history.goBack} className={'voltarLink'}>
                                    <FontAwesomeIcon icon={faChevronLeft} className={"icon"}/> Voltar
                                </p>
                            </h6>
                        </div>

                        {/*Resultado da Busca*/}
                        <div className="col-md-12 detailDiv">

                            {/*Anuncio*/}
                            <div className="col-md-12">
                                <form className="frmCard" id="frmCard">
                                    <div className="row">
                                        {/*IMAGEM*/}
                                        <div className="col-12 col-lg-3 col-xl-2 media detailImage">
                                            <img className="ml-3"
                                                 src={`${SITE_URL}/uploads/gallery/${content.image}`}
                                                 alt="Sem imagem"/>
                                        </div>

                                        {/*Descrição*/}
                                        <div className="col-12 col-md-7 col-lg-5 col-xl-7 detailDescription">
                                            <div className="row">

                                                <div className={'anuncioDescriptionTop col-12 col-xl-5'}>
                                                    <div>
                                                        <h5>{content.fipe.model}</h5>
                                                    </div>

                                                    <div>
                                                        <p>{content.year} - {content.fipe.fuel}</p>
                                                    </div>

                                                    <div>
                                                        <p>{content.color.name}</p>
                                                    </div>

                                                    <div>
                                                        <p className="registroData">
                                                            <FontAwesomeIcon icon={faClock}/> Cadastrado
                                                            em {new Intl.DateTimeFormat('pt-BR').format(created_at)}
                                                        </p>
                                                    </div>
                                                </div>


                                                <div className={"col-12 col-xl-7 divBtnsCardDetail"}>
                                                    <div className="row">

                                                        <div className="col-6 divBtnEviePropostaCardDetail">
                                                            <Link className={"row enviePropostaLinkCardDetail"} to={{
                                                                pathname: slugify(`/enviar-proposta/${content.id}`),
                                                            }}>
                                                                <div className={"txtEnvieProposta"}> </div>
                                                                <div className="iconEnvieProposta">
                                                                    <FontAwesomeIcon icon={faArrowAltCircleRight} size={"2x"}/>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="col-6 divBtnWhatsCardDetail">
                                                            <a className={"row whatsLinkCardDetail"} href={"https://wa.me/55" + content.user.cell_phone + "?text=" + uriMessage} target={"_blank"}>
                                                                <div className={"txtWhatsCardDetail"}> </div>
                                                                <div className="iconWhatsCardDetail">
                                                                    <FontAwesomeIcon icon={faWhatsapp} size={"2x"}/>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        {/*Preços/Condições*/}
                                        <div className="col-12 col-md-5 col-lg-4 col-xl-3 detailPrecos">

                                            {fipePercent &&
                                                <p className="percentFipe"
                                                   style={{color: setFipeColor(percent)}}>{content.fipe_percent_off} FIPE
                                                </p>
                                            }
                                            {fipePercent &&
                                                <div className="progress detailProgressBar">
                                                    <div className="progress-bar small fipeBar" id="fipeBar"
                                                         role="progressbar"
                                                         style={{
                                                             backgroundColor: setFipeColor(percent),
                                                             width: percent + '%'
                                                         }}
                                                         aria-valuenow="0" aria-valuemin="0"
                                                         aria-valuemax="100">
                                                    </div>
                                                </div>
                                            }
                                            {carValue &&
                                                <div>
                                                    <p className="value">
                                                        {new Intl.NumberFormat('pt-Br', {
                                                            style: 'currency',
                                                            currency: 'BRL'
                                                        }).format(content.price)}</p>
                                                </div>
                                            }
                                            {fipeValue &&
                                                <div>
                                                    <p className="tabelaFipe">Tabela
                                                        <img className="img-fluid" src={fipeLogo} alt="FIPE"/>
                                                    </p>
                                                </div>
                                            }
                                            {fipeValue &&
                                                <div>
                                                    <p className="fipeValue">
                                                        {new Intl.NumberFormat('pt-BR', {
                                                            style: 'currency',
                                                            currency: 'BRL'
                                                        }).format(content.fipe_price)}</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>

                        <div className="col-sm-12 col-md-12 infoDetailDiv">
                            <nav>
                                <div className="nav nav-tabs justify-content-start" id="nav-tab" role="tablist">
                                    <div className={"nav-item nav-link active nav-info"} >
                                        <p className="nav-item nav-link active nav-info" id="nav-info-tab">Informações</p>
                                    </div>
                                </div>
                            </nav>
                            <div className="tab-content py-3 px-3 px-sm-0 tabContent" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-info" role="tabpanel"
                                     aria-labelledby="nav-info-tab">
                                    <div className="col anuncioDescription">

                                        <div className={"infoBox"}>
                                            <div className={"infoDiv"}>
                                                <div className={"infoTitle"}>Tipo de Combustível</div>
                                                <div className={"infoValue"}>{content.fipe.fuel}</div>
                                            </div>

                                            <div className={"infoDiv"}>
                                                <div className={"infoTitle"}>Ano do modelo</div>
                                                <div className={"infoValue"}>{content.year}</div>
                                            </div>

                                            <div className={"infoDiv"}>
                                                <div className={"infoTitle"}>Portas</div>
                                                <div className={"infoValue"}>{content.doors}</div>
                                            </div>

                                            <div className={"infoDiv"}>
                                                <div className={"infoTitle"}>Cor</div>
                                                <div className={"infoValue"}>{content.color.name}</div>
                                            </div>

                                            <div className={"infoDiv"}>
                                                <div className={"infoTitle"}>Quilometragem</div>
                                                <div className={"infoValue"}>
                                                    {new Intl.NumberFormat('pt-Br').format(content.km)}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="opcionaisDetails">
                                            <div>
                                                <p>Opcionais</p>
                                            </div>

                                            <ul>
                                                <div className="opcionaisColumns">
                                                    <OpcionaisDetalhes options={content.optionals}/>
                                                </div>
                                            </ul>

                                            <div className="opcionaisDescricao">
                                                <div>
                                                    <p className="tiuloDescricao">Descrição</p>
                                                </div>
                                                <div>
                                                    <p className="descricao">{content.observation}</p>
                                                </div>
                                            </div>

                                            <div className="galeria">
                                                <div className="tituloGaleria">
                                                    <p>Galeria de Fotos</p>
                                                </div>

                                                <div style={{height: '5rem', marginBottom: '1rem'}}>
                                                    {

                                                        content.photos.map((item, i) => {

                                                            return <div className="mb-3" key={i}>
                                                                <div className="card">
                                                                    <button className={'openModalBtn'} onClick={() => {
                                                                        this.openModal();
                                                                        this.slideGalleryTo(i)
                                                                    }}>
                                                                        <img className="img-fluid"
                                                                             src={`${SITE_URL}/uploads/gallery/${item.filename}`}
                                                                             alt="Sem Imagem"/>
                                                                    </button>
                                                                </div>
                                                            </div>;
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <Modal
                                                ref={this.Modal}
                                                children={<SwiperGallery itens={content.photos}
                                                                         ref={this.SwiperGallery}/>}
                                                title={'Imagens do veículo'}
                                                width={'100%'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BotaoTopo scrollStepInPx="30" delayInMs="2" showAbove={171}/>
            </React.Fragment>
        );
    }
}

function getPercent(e) {
    return e.replace(/[%-]/g, '');
}

function setFipeColor(e) {
    let color = "black";
    if (e < 15) color = "#D0021B";  //14% ou menos vermelho
    if (e >= 15 && e <= 16) color = "#FFAA00";  //15% ou 16%   amarelo
    if (e > 16 && e <= 19) color = "#F78C46";  //17% até 19   laranja
    if (e > 19) color = "#34A853";  //20% ou mais  verde
    return color;
}

export default AdvertCardDetail;