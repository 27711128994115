import React from 'react';

class BrandsOption extends React.Component {

    render() {
        let brands = this.props.option;
        if(!brands) {
            return <option value=""> Selecione </option>;
        }

        return (
            brands.map(function (brand, i) {
                return <option key={i} value={brand.id}> {brand.name} </option>;
            })
        );
    }
}

export default BrandsOption;