import React from "react";
import api, {CARD_PER_PAGE, STORE_ID} from "./services/api";
import Pagination from "react-js-pagination";
import pageview from './services/ga';
import AdvertCard from "./components/advertsCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleNotch, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import history from "./services/history";

pageview(window.location.pathname + window.location.search);

class PopulateAdvertsOnLoad extends React.Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            clicks: 2,
            populate: [],
            total: 0,
            page: 1,
            disabled: 'disabled',
            result: null,
            activePage: 1,
            params: null,
            width: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    updateWindowDimensions() {
        this.setState({width: window.innerWidth});
    }

    // Scroll to ref function
    scrollToMyRef = e => {
        const elementTop = this.gate.offsetTop;
        window.scrollTo(0, elementTop);
    };

    /*
     * Recebe o numero de uma página
     * Passa pro state, checa disabled
     * e envia pra busca na api;
     */
    handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            page: pageNumber
        });
        this.setDisabled(pageNumber);
        this.fetchPageData(pageNumber).then(function(result) {
            history.push(`/pesquisa/${pageNumber}`);
        });

    }

    componentDidMount() {
        // verifica state.resultados
        // se vazio fetch a /cars sem param, se não, render
        if (!this.props.searchResults) {

            console.log('Eventualmente(Especificamente quando não há parâmetros alem da página) vim parar aqui )');
            let page = !this.props.searchPage ? 1 : this.props.searchPage;

            this.fetchPageData(page).then(function (result) {

            });
        }

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    async fetchPageData(page) {

        let searchParams = !this.props.searchParams ? {} : this.props.searchParams;
        searchParams.page = page;

        try {
            const result = await api.get(`/api/store_site/${STORE_ID}/cars`, {params: searchParams});
            this.props.updateSearch(result.data.data, searchParams, result.data.total, searchParams.page);
            this.setState({
                total: result.data.total,
                result: result.data.data,
                page: searchParams.page,
            });
            this.scrollToMyRef();

        } catch (error) {
            console.log(error);
        }

    }

    async setDisabled(page) {
        if (parseInt(page) >= 2 || parseInt(page)) {
            this.setState({disabled: ' '});
        } else {
            this.setState({disabled: 'disabled'});
        }
    };

    render() {

        console.log('-------------------- render de populate advert on load ------------------------------');
        const total = this.props.searchTotal;  // cancelar o total vindo do state e pegar o que vem do pai
        const content = this.props.searchResults;
        const page = this.props.searchPage;

        let width = this.state.width;
        let slotPerPage = width > 576 ? 5 : 1;


        if (!content) {
            return (
                <div className="col-md-12 resultLoading">
                    <div className={"advertPopulateLoadingTxt"}>
                        <FontAwesomeIcon icon={faCircleNotch} spin={true} className={"icon"}/> Carregando Anúncios...
                    </div>
                </div>
            );
        }

        return (
            <div className="col-md-12 resultReference" ref={elem => (this.gate = elem)}>
                <div className="result col-md-12">
                    <div className="col-md-12">
                        <h4>Existem {total}</h4>
                    </div>

                    {/*Anuncio*/}
                    <div className="col-md-12">
                        <form className="frmCard" id="frmCard">
                            <AdvertCard
                                content={this.props.searchResults}
                                displayFipePercent={false}
                                displayFipeValue={false}
                                displayCarValue={true}
                                buttonText={"Ver Veículo"}
                            />
                        </form>
                    </div>
                </div>
                <div className={"col-md-12"}>

                    <div className="col-md-12 paginationDiv">
                        <Pagination
                            itemClass="page-item" linkClass="page-link"
                            activePage={parseInt(page)}
                            itemsCountPerPage={CARD_PER_PAGE}
                            totalItemsCount={total}
                            pageRangeDisplayed={slotPerPage}
                            onChange={this.handlePageChange.bind(this)}
                            firstPageText={
                                <div className={"doubleChevron"}>
                                    <div  className={"doubleChevronDiv col-6"}>
                                        <FontAwesomeIcon icon={faChevronLeft} className={"icon"}/>
                                    </div>
                                    <div  className={"doubleChevronDiv col-6"}>
                                        <FontAwesomeIcon icon={faChevronLeft} className={"icon"}/>
                                    </div>
                                </div>}
                            prevPageText={<FontAwesomeIcon icon={faChevronLeft} className={"icon"}/>}

                            lastPageText={
                                <div className={"doubleChevron"}>
                                    <div  className={"doubleChevronDiv col-6"}>
                                        <FontAwesomeIcon icon={faChevronRight} className={"icon"}/>
                                    </div>
                                    <div  className={"doubleChevronDiv col-6"}>
                                        <FontAwesomeIcon icon={faChevronRight} className={"icon"}/>
                                    </div>
                                </div>}
                            nextPageText={<FontAwesomeIcon icon={faChevronRight} className={"icon"}/>}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default PopulateAdvertsOnLoad;