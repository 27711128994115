import React from 'react';
import Filter from '../../homeSearch';
import PopulateAdvertsOnLoad from '../../populateAdvertOnLoad';
import BotaoTopo from "../../components/BotaoTopo";
import AdvertisingTop from "../../components/AdvertisingTop/advertisingTop";

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchResults: null,
            searchParams: null,
            searchTotal: null,
            searchPage: this.props.match.params.page
        };
    }

    setSearchResults(results, params, total, page) {
        this.setState({
            searchResults: results,
            searchParams: params,
            searchTotal: total,
            searchPage: page,
        });
        this.props.match.params.page = page
    };

    render() {
        return (
            <React.Fragment>
                <div className="col-12 page-documento">
                    <AdvertisingTop/>
                </div>
                <div className="container" id="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row searchResultRow">
                                <div className="busca col-sm-12 col-lg-12 col-xl-12">
                                    <h2>Busca</h2>
                                </div>
                                <div className="col-12 col-lg-5 col-xl-3 filterDiv">
                                    <Filter
                                        searchResults={this.state.searchResults}
                                        searchParams={this.state.searchParams}
                                        searchTotal={this.state.searchTotal}
                                        searchPage={this.state.searchPage}
                                        updateSearch={this.setSearchResults.bind(this)}/>
                                </div>
                                <div className="col-12 col-lg-7 col-xl-9 resultDiv" id="resultDiv">
                                    <PopulateAdvertsOnLoad
                                        searchResults={this.state.searchResults}
                                        searchParams={this.state.searchParams}
                                        searchTotal={this.state.searchTotal}
                                        searchPage={this.state.searchPage}
                                        updateSearch={this.setSearchResults.bind(this)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BotaoTopo scrollStepInPx="30" delayInMs="2" showAbove={200}/>
            </React.Fragment>
        );
    };

}

export default Home;