import React from "react";
import BrandsOption from "./components/brandsOption";
import api from "./services/api";
import {STORE_ID} from "./services/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faTruck, faMotorcycle, faChevronDown, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

class Filter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clicks: 2,
            populate: [],
            marcas: [],
            modelos: [],
            ano: [],
            preco: [],
            km: [],
            cores: [],
            combustivel: [],
            portas: [],
            opcionais: [],
            car_type: null,
            brand_id: null,
            model_id: null,
            optionals_id: []
        };

        this.padBotZero = {
            marginBottom: '0'
        };

    }

    componentDidMount() {

        this.fetchPageData().then((data) => {
            let populate = data.data;
            this.setState({populate: populate, marcas: populate.brands});


            this.populateModels(populate.models);

            let ano = [];
            for (var a = 0; a < populate.years.length; a++) {
                ano.push(<option key={a} value={populate.years[a]}> {populate.years[a]} </option>);
            }

            //POPULATE PRICES
            let preco = [];
            for (var p = 0; p < populate.price.length; p++) {
                preco.push(<option key={p} value={populate.price[p]}>{new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                }).format(populate.price[p])}</option>);
            }

            //POPULATE KM
            let km = [];
            for (var k = 0; k < populate.km.length; k++) {
                km.push(<option key={k} value={populate.km[k]}>{populate.km[k]}</option>);
            }

            //POPULATE COLORS
            let cores = [];
            for (var c = 0; c < populate.colors.length; c++) {
                cores.push(<option key={c} value={populate.colors[c].id}>{populate.colors[c].name}</option>);
            }

            //POPULATE FUEL
            let combustivel = [];
            for (var f = 0; f < populate.fuel.length; f++) {
                combustivel.push(<option key={f} value={populate.fuel[f].value}>{populate.fuel[f].label}</option>);
            }

            //POPULATE DOORS
            let qtdPortas = [];
            let portas = {
                'min': 0,
                'max': 4
            };
            for (var d = 0; d < populate.doors.length; d++) {
                qtdPortas.push(populate.doors[d]);
            }
            portas = {
                'min': qtdPortas.shift(),
                'max': qtdPortas.pop()
            };

            //POPULATE OPTIONALS
            this.populateOptionals(data.data.optionals)


            this.setState({
                marcas: populate.brands,
                ano: ano,
                preco: preco,
                km: km,
                cores: cores,
                combustivel: combustivel,
                portas: portas

            });
        });
    };

    incrementDoor = () => {
        parseInt(this.state.clicks) < this.state.portas['max'] ?
            this.setState({clicks: this.state.clicks + 1}) :
            this.setState({clicks: this.state.clicks})
    };

    decreseDoor = () => {
        parseInt(this.state.clicks) > this.state.portas['min'] ?
            this.setState({clicks: this.state.clicks - 1}) :
            this.setState({clicks: this.state.clicks})
    };

    fetchPageData = async () => {
        try {
            const result = await api.get(`/api/store_site/${STORE_ID}/populate`);

            const resultTypeCar = await api.get(`/api/store_site/${STORE_ID}/populate/type/0`);

            result.data.data.brands     = resultTypeCar.data.data.brands;
            result.data.data.optional   = resultTypeCar.data.data.optional;
            result.data.data.models     = resultTypeCar.data.data.models;

            return result.data;
        } catch (error) {
            console.log(error);
        }
    };

    populateBrands = (data) => {
        //POPULATE BRANDS
        let marcas = [];

        for (let i = 0; i < data.length; i++) {
            marcas.push(<option key={data[i].name + i} value={data[i].id}> {data[i].name} </option>);
        }

        this.setState({marcas: data});
    };

    populateModels = (data) => {
        //POPULATE MODELS
        let modelos = [];

        for (let m = 0; m < data.length; m++) {
            modelos.push(<option key={data[m].name + m} value={data[m].id}> {data[m].name} </option>);
        }

        this.setState({modelos: modelos});
    };

    populateOptionals = (data) => {
        //POPULATE OPTIONALS
        let opcionais = [];
        for (let o = 0; o < data.length; o++) {
            opcionais.push(
                <div className="col-md-12 custom-control custom-checkbox" key={data[o].id}>
                    <input type="checkbox" className="custom-control-input" id={data[o].id} value={data[o].id}
                           onChange={this.handleOptionalsChange}/>
                    <label className="custom-control-label"
                           htmlFor={data[o].id}>{data[o].name}</label>
                </div>
            );
        }
        this.setState({opcionais: opcionais});

    };

    handleCarType = async (car_type) => {
        // setstate para rodar a consulta
        this.setState({car_type: car_type});

        try {
            const result = await api.get(`/api/store_site/${STORE_ID}/populate/type/${car_type}`);
            this.populateBrands(result.data.data.brands);
            this.populateModels(result.data.data.models);
            this.populateOptionals(result.data.data.optional);
        } catch (error) {
            console.log(error);
        }

    };

    handleBrandChange = async (e) => {
        let brand_id = e.target.value;
        //set state
        this.setState({brand_id: brand_id});

        try {
            const result = await api.get(`/api/store_site/${STORE_ID}/populate/models/${brand_id}`);
            this.populateModels(result.data.data.models);
        } catch (error) {
            console.log(error);
        }
    };

    handleInputChange = (e) => {
        let target = e.target;
        let value = target.value;
        let name = target.name;
        // console.log('name: ' + name + "  " + "value: " + value);
        this.setState({
            [name]: value
        });
    };

    handleInputChangeArray = (e) => {
        let target = e.target;
        let value = target.value;
        let name = target.name;
        // console.log('name: ' + name + "  " + "value: " + value);
        this.setState({
            [name]: [value]
        });
    };

    handleOptionalsChange = (e) => {
        const target = e.target;
        const value = target.value;
        const checked = target.checked;
        let array_optionals = this.state.optionals_id;
        if (checked) {
            array_optionals.push(value);
            // this.setState({optionals_id: this.state.optionals_id.concat(value)});
        } else {
            array_optionals.pop(value);
            // this.setState( {optionals_id: optionals_id.pop(value)} );
        }

        this.setState({optionals_id: array_optionals});
        console.log(array_optionals);
    };

    handleSearch = async (e) => {
        console.log(this.state);
        let formData = {
            car_type: this.state.car_type,
            brand_id: this.state.brand_id,
            model_id: this.state.model_id,
            start_year: this.state.start_year,
            end_year: this.state.end_year,
            fuel: this.state.fuel,
            start_price: this.state.start_price,
            end_price: this.state.end_price,
            doors: this.state.doors,
            color_ids: this.state.color_ids,
            start_km: this.state.start_km,
            end_km: this.state.end_km,
            optionals_id: this.state.optionals_id,
            page: 1
        };

        try {
            const result = await api.get(`/api/store_site/${STORE_ID}/cars`, {
                params: formData
            });

            this.props.updateSearch(result.data.data, formData, result.data.total, formData.page);


        } catch (error) {
            console.log(error);
        }

    };


    render() {

        return (
            <React.Fragment>
                <div className="col-md-12 buscaDiv">
                    <div className="col-md-12 filterTitle">
                        <h4>Filtro</h4>
                    </div>

                    <div className="col-md-12 btn-group btn-group-toggle typeBtnGroup" data-toggle="buttons">
                        <label className="active btn btn-secondary" onClick={(e) => this.handleCarType(0)}>
                            <input type="radio" name="options" id="car"/>
                            <FontAwesomeIcon icon={faCar} size={"lg"} className={"icon"}/>
                        </label>
                        <label className="btn btn-secondary" onClick={(e) => this.handleCarType(5)}>
                            <input type="radio" name="options" id="truck"/>
                            <FontAwesomeIcon icon={faTruck} size={"lg"} className={"icon"}/>
                        </label>
                        <label className="btn btn-secondary" onClick={(e) => this.handleCarType(8)}>
                            <input type="radio" name="options" id="motorcycle"/>
                            <FontAwesomeIcon icon={faMotorcycle} size={"lg"} className={"icon"}/>
                        </label>
                    </div>

                    {/* Form */}

                    <form className="frmFilter" id="frmFilter">
                        <div className="form-group">
                            <label htmlFor="marca">Marca</label>
                            <select className="form-control" id="marca" onChange={this.handleBrandChange}>
                                <option>Selecione</option>
                                <BrandsOption option={this.state.marcas}/>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="modelo">Modelo</label>
                            <select className="form-control" id="modelo" name="model_id"
                                    onChange={this.handleInputChange}>
                                <option>Selecione</option>
                                {this.state.modelos}
                            </select>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <h6>ANO</h6>
                            </div>
                            <div className="col dir">
                                <label htmlFor="anoDe">De</label>
                                <select className="form-control" id="anoDe" name="start_year"
                                        onChange={this.handleInputChange}>
                                    <option>Selecione</option>
                                    {this.state.ano}
                                </select>
                            </div>

                            <div className="col esq">
                                <label htmlFor="anoAte">Até</label>
                                <select className="form-control" id="anoAte" name="start_year"
                                        onChange={this.handleInputChange}>
                                    <option>Selecione</option>
                                    {this.state.ano}
                                </select>
                            </div>
                        </div>

                        <br/>
                        <div className="row">
                            <div className="col-md-12">
                                <h6>PREÇO</h6>
                            </div>

                            <div className="col dir">
                                <label htmlFor="precoDe">De</label>
                                <select className="form-control" id="precoDe" name="start_price"
                                        onChange={this.handleInputChange}>
                                    <option>Selecione</option>
                                    {this.state.preco}
                                </select>
                            </div>

                            <div className="col esq">
                                <label htmlFor="precoAte">Até</label>
                                <select className="form-control" id="precoAte" name="end_price"
                                        onChange={this.handleInputChange}>
                                    <option>Selecione</option>
                                    {this.state.preco}
                                </select>
                            </div>
                        </div>

                        <br/>
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="col-md-12">KILOMETRAGEM</h6>
                            </div>

                            <div className="col dir">
                                <label htmlFor="kmDe">De</label>
                                <select className="form-control" id="kmDe" name="start_km"
                                        onChange={this.handleInputChange}>
                                    <option>Selecione</option>
                                    {this.state.km}
                                </select>
                            </div>

                            <div className="col esq">
                                <label htmlFor="kmAte">Até</label>
                                <select className="form-control" id="kmAte" name="end_km"
                                        onChange={this.handleInputChange}>
                                    <option>Selecione</option>
                                    {this.state.km}
                                </select>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="cor">Cor</label>
                            <select className="form-control" id="cor" name="color_ids"
                                    onChange={this.handleInputChangeArray}>
                                <option>Selecione</option>
                                {this.state.cores}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="combustivel">Combustível</label>
                            <select className="form-control" id="combustivel" name="fuel"
                                    onChange={this.handleInputChangeArray}>
                                <option>Selecione</option>
                                {this.state.combustivel}
                            </select>
                        </div>

                        <label htmlFor="portasDiv">Portas</label>
                        <div className="input-group-append divPortas" id={"portasDiv"}>
                            <button type="button" className="btnPortas" onClick={this.decreseDoor}>
                                <FontAwesomeIcon icon={faChevronLeft} className={"icon"}/>
                            </button>
                            <label htmlFor="portas" style={this.padBotZero} id={"labelDoor"}>
                                <input type="number" className="inputPortas input-group" readOnly disabled
                                       id="portas" value={this.state.clicks} name="doors"
                                       onChange={this.handleInputChangeArray}/>
                            </label>
                            <button type="button" className="btnPortas" onClick={this.incrementDoor}>
                                <FontAwesomeIcon icon={faChevronRight} className={"icon"}/>
                            </button>
                        </div>

                        <div className="accordion" id="accordion">
                            <br/>
                            <div className="headingDiv" id="headingDiv">
                                <div className="row" data-toggle="collapse" data-target="#collapseDiv"
                                     aria-expanded="true" aria-controls="collapseDiv">
                                    <h6 className=" col col-md-6">
                                        OPCIONAIS
                                    </h6>

                                    <div className="col col-md-6 endRightIcon">
                                        <p className="col">
                                            <FontAwesomeIcon icon={faChevronDown} className={"icon"}/>
                                        </p>

                                    </div>
                                </div>
                            </div>

                            <div id="collapseDiv" className="collapse" aria-labelledby="headingDiv"
                                 data-parent="#accordion">

                                <div className="form-group" id="#chkCollapse">
                                    {this.state.opcionais}
                                </div>
                            </div>

                            <hr/>

                            <br/>
                            <div>
                                <button type="button" className="btn btn-danger btn-block"
                                        onClick={this.handleSearch}>Buscar
                                </button>
                            </div>
                            <br/>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    }
}

export default Filter;