import axios from 'axios';

const baseURL = 'https://srv.aplicativorepasse.com.br';
// const baseURL = 'https://beta.aplicativorepasse.com.br';

export const SITE_URL = baseURL;

// export const STORE_ID = 6;
export const STORE_ID = process.env.REACT_APP_CLIENT_ID;
export const GOOGLE_ID = process.env.REACT_APP_ANALYTICS_ID;
export const STORE_DIR = process.env.REACT_APP_CLIENT_DIR;

export const CARD_PER_PAGE = 15;

const api = axios.create({
    baseURL,
});

export default api;
