import React from 'react';

import Swiper from 'react-id-swiper';
import 'swiper/dist/css/swiper.css';
import {SITE_URL} from "../../services/api.js";


class SwiperGallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            gallerySwiper: null,
            thumbnailSwiper: null,
            selectedSlide: null
        };

        this.galleryRef = this.galleryRef.bind(this);
        this.thumbRef = this.thumbRef.bind(this);

    }

    setSelectedIndex = index => {
        this.setState({selectedSlide: index});
    };

    componentWillUpdate(nextProps, nextState, nextContext) {

        if (nextState.gallerySwiper && nextState.thumbnailSwiper) {
            const {gallerySwiper, thumbnailSwiper} = nextState;
            gallerySwiper.controller.control = thumbnailSwiper;
            thumbnailSwiper.controller.control = gallerySwiper;
        }
    }

    galleryRef(ref) {
        if (ref) this.setState({gallerySwiper: ref.swiper})
    }

    thumbRef(ref) {
        if (ref) this.setState({thumbnailSwiper: ref.swiper})
    }

    render() {

        const itens = this.props.itens;

        const gallerySwiperParams = {
            containerClass: 'swiper-container gallery-top',
            spaceBetween: 10,
            slidesPerView: 1,
            keyboard: true,
            initialSlide: this.state.selectedSlide,
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            normalizeSlideIndex: true
        };

        const thumbnailSwiperParams = {
            containerClass: 'swiper-container gallery-thumbs',
            spaceBetween: 5,
            slidesPerView: 5,
            touchRatio: 0.2,
            initialSlide: this.state.selectedSlide,
            normalizeSlideIndex: true,
            centeredSlides: true,
            slideToClickedSlide: true
        };


        return (

            <React.Fragment>
                <Swiper {...gallerySwiperParams} ref={this.galleryRef}
                        shouldSwiperUpdate={true}
                >
                    {
                        itens.map(function (item, i) {
                            return <div key={i}>
                                <img className="img-fluid carouselImage" src={`${SITE_URL}/uploads/gallery/${item.filename}`}
                                     alt="Sem Imagem"/>
                            </div>
                        })
                    }

                </Swiper>
                <Swiper {...thumbnailSwiperParams} ref={this.thumbRef}
                        shouldSwiperUpdate={true}
                >

                    {
                        itens.map(function (item, i) {
                            return <div key={i}>
                                <img className="img-thumbnail" src={`${SITE_URL}/uploads/gallery/${item.filename}`}
                                     alt="Sem Imagem"/>
                            </div>
                        })
                    }
                </Swiper>
            </React.Fragment>


        );
    }
}

export default SwiperGallery;