import React from 'react';
import classNames from  'classnames';
import validator from 'validator';
import api, {STORE_ID} from '../../services/api';
import Swal from 'sweetalert2';


export default class FormContato extends React.Component {
    formDefaults = {
        name:  {value: '', isValid: true, message: ''},
        email: { value: '', isValid: true, message: '' },
        subject: { value: '', isValid: true, message: '' },
        message: { value: '', isValid: true, message: '' }
    };

    constructor(props) {
        super(props);

        this.state = { ...this.formDefaults};
    }


    onChange = (e) => {
        const state = {
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                value: e.target.value,
            }
        };

        this.setState(state);
    };

    onSubmit = async (e) => {
        e.preventDefault();
        // reset states before the validation procedure is run.
        this.resetValidationStates();
        if (this.formIsValid()) {

            const name = { ...this.state.name };
            const email = { ...this.state.email };
            const subject = { ...this.state.subject };
            const message = { ...this.state.message };
            // form processing here....
            try {
                 await api.post(`/api/store_site/${STORE_ID}/contact`, {
                    name: name.value,
                    email: email.value,
                    subject: subject.value,
                    message: message.value
                    });
                Swal.fire('Sucesso', 'Obrigado por entrar em contato, responderemos assim que possível.', 'success');
                this.resetForm();

            } catch (error) {
                if (error.response.status === 422) {
                    this.handleValidationError(error.response.data.errors);
                } else {
                    Swal.fire('Erro', 'Ocorreu um erro, verifique os campos e tente novamente', 'error');
                }
            }
        }
    };

    handleValidationError = (errors) => {
        const name = { ...this.state.name };
        const email = { ...this.state.email };
        const subject = { ...this.state.subject };
        const message = { ...this.state.message };

        Object.keys(errors).map((error, index) => {

             if (error === 'name') {
                 name.isValid = false;
                 name.message = errors[error][0];
             }

             if (error === 'email') {
                 email.isValid = false;
                 email.message = errors[error][0];
             }

             if (error === 'subject') {
                 subject.isValid = false;
                 subject.message = errors[error][0];
             }

             if (error === 'message') {
                 message.isValid = false;
                 message.message = errors[error][0];
             }

             return "";

        });

        this.setState({name, email, subject, message});

    };

    formIsValid = () => {
        const name = { ...this.state.name };
        const email = { ...this.state.email };
        const subject = { ...this.state.subject };
        const message = { ...this.state.message };
        let isGood = true;

        if (validator.isEmpty(name.value)) {
            name.isValid = false;
            name.message = 'Preencha o seu nome';
            isGood = false;
        } else {
            name.isValid = true;
            name.message = '';
        }

        if (!validator.isEmail(email.value)) {
            email.isValid = false;
            email.message = 'Email inválido';
            isGood = false;
        } else {
            email.isValid = true;
            email.message = '';
        }

        if (validator.isEmpty(subject.value)) {
            subject.isValid = false;
            subject.message = 'Preencha o assunto';
            isGood = false;
        } else {
            subject.isValid = true;
            subject.message = '';
        }

        if (validator.isEmpty(message.value)) {
            message.isValid = false;
            message.message = 'Preencha sua mensagem';
            isGood = false;
        } else {
            message.isValid = true;
            message.message = '';
        }



        if (!isGood) {
            this.setState({
                name,
                email,
                subject,
                message,
            });
        }

        return isGood;
    };

    resetValidationStates = () => {
        // make a copy of everything in state
        const state = JSON.parse(JSON.stringify(this.state));

        /*
        loop through each item in state and if it's safe to assume that only
        form values have an 'isValid' property, we can use that to reset their
        validation states and keep their existing value property. This process
        makes it easy to set all validation states on form inputs in case the number
        of fields on our form grows in the future.
        */
        Object.keys(state).map(key => {
            if (state[key].hasOwnProperty('isValid')) {
                state[key].isValid = true;
                state[key].message = '';
            }
            return "";
        });

        this.setState(state);

    };

    resetForm = () => {
        this.setState({...this.formDefaults});
    };


    render() {

        const {name, email, subject, message } = this.state;

        const nameGroupClass = classNames('form-control', { 'is-invalid': !name.isValid });

        const emailGroupClass = classNames('form-control', { 'is-invalid': !email.isValid });

        const subjectGroupClass = classNames('form-control', { 'is-invalid': !subject.isValid });

        const messageGroupClass = classNames('form-control', { 'is-invalid': !message.isValid });

        return (
            <React.Fragment>
                    <div className="col-lg-8 col-md-12">
                        <div className="form-contato">
                            <form method="POST" acceptCharset="UTF-8" onSubmit={this.onSubmit}>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name">Nome</label>
                                        <input type="text"
                                               className={nameGroupClass}
                                               value={name.value}
                                               onChange={this.onChange}
                                               id="name" name="name"
                                               placeholder="Nome"/>
                                        <small className="invalid-feedback">
                                            {name.message}
                                        </small>
                                        <label htmlFor="email">
                                            E-mail
                                        </label>
                                        <input type="email"
                                               className={emailGroupClass}
                                               value={email.value}
                                               onChange={this.onChange}
                                               id="email" name="email"
                                               placeholder="E-mail"/>
                                        <small className="invalid-feedback">
                                            {email.message}
                                        </small>
                                        <label htmlFor="subject">
                                            Assunto
                                        </label>
                                        <input type="text"
                                               className={subjectGroupClass}
                                               value={subject.value}
                                               onChange={this.onChange}
                                               id="subject" name="subject"
                                               placeholder="Assunto"/>
                                        <small className="invalid-feedback">
                                            {subject.message}
                                        </small>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name">
                                            Mensagem
                                        </label>
                                        <textarea name="message"
                                                  id="message"
                                                  className={messageGroupClass}
                                                  value={message.value}
                                                  onChange={this.onChange}
                                                  rows="8"
                                                  cols="25"
                                                  placeholder="Mensagem" />
                                        <small className="invalid-feedback">
                                            {message.message}
                                        </small>

                                    </div>
                                </div>
                                <div className="col-md-6 float-right formContatoOptions">
                                    <button type="submit" className="btn btn-danger verDetalhe" id="btnContactUs">
                                        Enviar Mensagem
                                    </button>
                                    <button type="button" className={"btn btnLimpar"} onClick={this.resetForm}>Limpar</button>
                                </div>
                            </form>
                        </div>
                    </div>

            </React.Fragment>
        );

    }
}