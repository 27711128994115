import React, {Suspense} from 'react';
import './index.css';
import FormContato from '../../components/FormContato';
import BotaoTopo from "../../components/BotaoTopo";
import {STORE_DIR} from "../../services/api";
import AdvertisingTop from "../../components/AdvertisingTop/advertisingTop";

const Escritorio = React.lazy(() => import('../../components/clients/' + STORE_DIR + '/Escritorio/index'));
const ClientMap = React.lazy(() => import('../../components/clients/' + STORE_DIR + '/Map/index'));

export default class PageContato extends React.Component {

    render() {

        return (
            <React.Fragment>
                <div className="col-12 page-documento">
                    <AdvertisingTop/>
                </div>
                <div className={"container"}>
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="busca">Contato</h2>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 page-contato">
                        <div className="row">

                            {/*Formulário de Contato*/}
                            <FormContato/>

                            {/*Endereço e Contato*/}
                            <Suspense fallback={<div>Carregando...</div>}>
                                <Escritorio/>
                            </Suspense>
                        </div>

                        {/*Google Map*/}
                        <Suspense fallback={<div>Carregando...</div>}>
                            <ClientMap/>
                        </Suspense>
                    </div>
                </div>
                <BotaoTopo scrollStepInPx="30" delayInMs="2" showAbove={171}/>
            </React.Fragment>
        )
    }


}