import React from 'react';

class AdvertisingTop extends React.Component {

    render() {

        return <div>
            <div className={"buscaImg"}> </div>
        </div>
    }
}

export default AdvertisingTop;