import React, { Component } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

import './style.sass';

/**
 * @param {boolean} props.isActive
 * @param {string} props.title
 * @param {string} props.customClass
 * @param {string} props.size
 * @param {string} props.width
 */
class Modal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: false,
        };

        this.handleEscPress();
    }

    static defaultProps = {
        isActive: false,
        customClass: '',
        size: '',
    };

    componentDidMount() {
        this.setState({ isActive: this.props.isActive });
    }

    openModal = () => this.setState({ isActive: true });

    closeModal = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }

        this.setState({ isActive: false });
    };

    handleEscPress = () => {
        window.addEventListener('keydown', event => {
            if (event.code === 'Escape' && this.state.isActive && !this.props.isStrict) {
                this.closeModal();
            }
        });
    };

    render() {
        const { customClass, size } = this.props;

        const modalStatusClass = classNames({
            Modal: true,
            [customClass]: customClass,
            [size]: true,
            'is-active': this.state.isActive,
        });

        return (
            <div className={modalStatusClass}>
                {this.props.isStrict ? (
                    <div className="Modal-bg" />
                ) : (
                    <div className="Modal-bg" onClick={this.closeModal} />
                )}

                <div className="Modal-box">

                    <div className="Modal-header modalHeader">
                        <div className="Modal-title">{this.props.title}</div>

                        {!this.props.isStrict && (
                            <div className="Modal-close" onClick={this.closeModal}>
                                <FontAwesomeIcon icon={faWindowClose} size={"2x"}/>
                            </div>
                        )}
                    </div>
                    <div className="Modal-body">
                        <div className="Modal-content">{this.props.children}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;