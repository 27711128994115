import React from 'react';
// import './index.css';
import AdvertCardDetail from '../../components/advertCardDetail';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import api, {STORE_ID} from "../../services/api";
import history from "../../services/history";
import AdvertisingTop from "../../components/AdvertisingTop/advertisingTop";


export default class PageDetalhes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            populate: false,
            id: this.props.match.params.id
        };
    }

    async componentDidMount() {

        try {
            const results = await api.get(`/api/store_site/${STORE_ID}/car/${this.state.id}`);
            this.setState({populate: results.data.data});
        } catch (error) {
            console.log(error);
        }
    }

    render() {

        const content = this.state.populate;

        if (!content) {
            return (<React.Fragment>

                    <div className="col-12 page-documento">
                        <AdvertisingTop/>
                    </div>

                    <div className="container" id="content">

                        <div className="row">
                            <div className="voltar col-md-12">
                                <h6>
                                    <p onClick={history.goBack} className={'voltarLink'}>
                                        <FontAwesomeIcon icon={faChevronLeft} className={"icon"}/> Voltar
                                    </p>
                                </h6>
                            </div>
                            <div className="col-md-12 detailLoading">
                                <div className={"advertDetailLoadingTxt"}>
                                    <FontAwesomeIcon icon={faCircleNotch} spin={true} className={"icon"}/> Carregando
                                    Detalhes...
                                </div>
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            );
        } else {
            return  <AdvertCardDetail
                content={content}
                displayFipePercent={false}
                displayFipeValue={false}
                displayCarValue={true}>
            </AdvertCardDetail>;
        }
    }
}

