import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";

class BotaoTopo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            intervalId: 0,
            pageYOffset: window.pageYOffset,
            show: false,
            color: false
        };

        this.handleScroll = this.handleScroll.bind(this);
    }


    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }

        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        this.setState({intervalId: intervalId});
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.pageYOffset > this.props.showAbove) {
            if (!this.state.show) {
                this.setState({show: true})
            }
        } else {
            if (this.state.show) {
                this.setState({show: false})
            }
        }
    };

    render() {
        return (
            <button title='Voltar ao topo' className='botaoTopo' onClick={() => {
                this.scrollToTop()
            }}
                    style={{
                        display: (!this.state.show ? 'none' : 'grid'),
                        visibility: (!this.state.show ? 'hidden' : 'visible')
                    }}
                    onScroll={this.handleScroll()}
                    onWheel={this.handleScroll()}
                    onTouchStart={this.handleScroll()}>
                <FontAwesomeIcon icon={faArrowUp} size={"2x"} className={"arrow-up"}/>
            </button>
        );
    }
}

export default BotaoTopo;