import React from 'react';

class OpcionaisDetalhes extends React.Component {

    render() {
        let opcionais = this.props.options;
        if(!opcionais) {
            return <li> </li>;
        }

        return (
            opcionais.map(function (opcional, i) {
                return <li key={i}> {opcional.name} </li>;
            })
        );
    }
}

export default OpcionaisDetalhes;